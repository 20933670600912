<template>
  <div class="analysis_warp"
       id="pdfDom"
       ref="wrap">
    <div class="top">
      <TopCard ref="TopCardRef"
               text="答案解析"
               :showBack="true" />
    </div>
    <div class="contents">
      <!-- 题目详情 -->
      <div class="left"
           ref="errorLeftRef">
        <!-- 题目内容 -->
        <div class="topic box">
          <div class="title">
            <!-- <el-button @click="getPdf()">666</el-button> -->
            <div class="txtTitle"><span>题目ID:{{question.question_id}}</span>  <span>题目分数:{{question.question_score}}</span></div>
            <div class="next_btn">

              <div class='txt'
                   style="cursor:pointer"
                   @click="nextTopic(1)">
                <div style="height: 10rem;"> <img src="@/assets/icons/Left.png"
                       alt=""></div>
                <div>上一题</div>
              </div>
              <div class='txt'
                   style="cursor:pointer"
                   @click="nextTopic(2)">
                <div style="height: 10rem;"> <img src="@/assets/icons/Right.png"
                       alt=""></div>
                <div>下一题</div>
              </div>
              <div class="txt"
                   v-if="question.has_favorite  ==0 "
                   style="cursor:pointer"
                   @click="collect()">
                <div style="height: 10rem;"><img src="@/assets/icons/收藏.png"
                       alt=""></div>
                <div>收 藏</div>
              </div>
              <div class="txt"
                   v-else
                   style="cursor:pointer"
                   @click="cancelCollect()">
                <div style="height: 10rem;"> <img src="@/assets/icons/收藏实心.png"
                       alt=""></div>
                <div>收 藏</div>
              </div>

              <div class='txt'
                   @click="fatie"
                   style="cursor:pointer">
                <div style="height: 10rem;"> <img src="@/assets/icons/发帖子.png"
                       alt=""></div>
                <div>发 帖</div>
              </div>
            </div>
          </div>
          <div class="content"
         :style="{'height':`calc(100% - ${opertion_heigth?360:100}rem)`}">
      <div class="coll_wrap">
        <el-collapse v-model="activeNames"
                    >

          <el-collapse-item title="题目内容"
                           
                            name="1">
            <div v-if="question.question_head_content"
                 class="topic"
                 v-html="question.question_head_content"></div>
            <div class="topic"
                 v-html="question.question_content"></div>
          </el-collapse-item>
          <el-collapse-item title="我的答案"
                            name="4">
            <div class="topic"
                 v-html="question.user_answer"></div>
          </el-collapse-item>
          <el-collapse-item title="题目答案"
                            name="3">
            <div class="topic"
                 v-html="question.question_answer"></div>
          </el-collapse-item>
          <el-collapse-item title="题目解析"
                            name="2">
            <div class="topic"
                 v-html="question.question_explain"></div>
          </el-collapse-item>
         
       
          <el-collapse-item title="我的笔记"
                           
                            name="5">
            <div>
              <el-input :placeholder="comment?comment:'请输入笔记'"
                        type="textarea"
                        v-model="comment"
                        v-show='isFocus'
                        :autofocus="true"
                        @change='editComment'
                        @blur='editComment'></el-input>
              <div v-show='!isFocus'
                   class="comment_text"><span>{{comment?comment:"暂无笔记"}}</span><span class="comment_icon"
                      @click="showInput"><img src="@/assets/error_book/icon-评论.png"
                       alt=""></span></div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
          <!-- <div class="topic_ontent"> -->
            <!-- 这是题目内容 -->
            <!-- <div class="topic">题目id: {{question.question_id}}</div>
            <div class="topic"
                 v-if="question.question_head_content"
                 v-html="question.question_head_content"></div>
            <div class="topic"
                 v-html="question.question_content"></div>
          </div> -->
        </div>
        <!-- 题目解析 -->
        <!-- <div class="solution box">
          <div class="title">
            <div class="txt">题目解析</div>
          </div>
          <div class="topic_ontent">
            <div class="topic"
                 v-html="question.question_explain"></div>
          </div>
          <div class="answer">
            <div class="comment_title">答案</div>
            <div v-html="question.question_answer"></div>
          </div>
          <div class="answerImg"
               v-show='question.images||question.user_answer'>
            <div class="comment_title">我的答案</div>
            <div>
              <div>{{question.user_answer}}</div>
              <el-image :src="$addAllHeader(item)"
                        alt=""
                        v-for="item,index in question.images"
                        :key='index'
                        :preview-src-list="$addForHeader(question.images)"></el-image>
            </div>
          </div>
          <div class="comment">
            <div class="comment_title">我的笔记</div>
            <div>
              <el-input :placeholder="comment?comment:'请输入笔记'"
                        type="textarea"
                        v-model="comment"
                        v-show='isFocus'
                        :autofocus="true"
                        @change='editComment'
                        @blur='editComment'></el-input>
              <div v-show='!isFocus'
                   class="comment_text"><span>{{comment?comment:"暂无笔记"}}</span><span class="comment_icon"
                      @click="showInput"><img src="@/assets/error_book/icon-评论.png"
                       alt=""></span></div>
            </div>
          </div>
        </div> -->
      
        <!-- 相似题型，为您推荐,暂不显示 -->
        <div class="other box"
             v-show='false'>
          <div class="list">
            <div class="subtitle">相似题型</div>
            <div class="item">相似题型1</div>
            <div class="item">相似题型1</div>
          </div>
          <div class="list">
            <div class="subtitle">为您推荐</div>
            <div class="item">为您推荐1</div>
            <div class="item">为您推荐1</div>
          </div>

        </div>
      </div>
      <!-- 分屏拖动位置 -->
      <button class="move_line"
              title="鼠标按住拖动"
              @mousedown="moveAble"
              ref='moveLine'>
      </button>
      <!-- 右侧知识点导图 -->
      <div class="right">
        <div class="map">
          <LineMap ref="mapRef" />
          <!-- @setFloatData="setFloatData" -->
        </div>
      </div>
      <!-- 浮动窗口，知识点相关内容 -->
      <div class="float_window"
           v-if="floatData.title&&showFlag">

        <FloatWindow :windowData="floatData"
                     ref="windowRef"
                     v-if="floatData.title" />

      </div>
    </div>
    <Tag ref="TagRef" />
    <Form ref="FormRef" />
  </div>
</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import TopicInfo from './opertion.vue'
import FloatWindow from './floatWindow.vue'
import LineMap from './lineMap.vue'
import { favoriteInfo, errorInfo, getPoint, getKnowledge, getLecture, editComment, editCollectComment, getCapability, delCollect } from '@/api/errorbasis.js'
import Tag from './tag1.vue'
import Form from '@/views/discuss/components/form.vue'




export default {
  data () {
    return {
      list: [],
      activeNames: ['1', '2', '3'],
      boardList: [
        { board_name: '语文', board_id: 1 },
        { board_name: '数学', board_id: 2 },
        { board_name: '英语', board_id: 3 },
        { board_name: '物理', board_id: 4 },
        { board_name: '化学', board_id: 5 },
        { board_name: '生物', board_id: 6 },
        { board_name: '服务区', board_id: 11 },
      ],
      index: 0,
      info: {},
      question: {},
      questionKnowledge: [],//知识点数据
      questionCheckPoint: [],//考点数据
      textarea: '',
      floatData: {},
      compactData: {},
      comment: '',
      isFocus: false,
      thisTopicId: '',
      sessionNodeList: [],
      isMove: false,
      clickX: 0,
      clickLeftWidth: 0,
      showFlag: false,
      has_favorite: null,
      htmlTitle: "测试导出文件", // 生成pdf的名称 
    }
  },
  components: {
    TopCard, TopicInfo, FloatWindow, LineMap, Tag, Form
  },
  computed: {
    // questionContent () {
    //   this.list[this.index] = this.list[this.index] ? this.list[this.index] : {
    //     paper_question_no: '',
    //     question_code_head: '',
    //     question_content: '',
    //   }
    //   return this.list[this.index]
    // }
  },
  // /doPaper
  destroyed () {
    // window.sessionStorage.removeItem('tipic_id')
    // window.sessionStorage.removeItem('sessionNodeList')
  },
  created () {
    // this.has_favorite = Number(this.question.has_favorite)
  },
  mounted () {

    let tId = window.sessionStorage.getItem("tipic_id") //缓存当前题目id,用于在下一题刷新页面时不改变题目
    if (tId) {
      this.thisTopicId = tId
    }
    else {
      this.thisTopicId = this.$route.query.question_id || this.$route.query.user_paper_detail_id
    }
    let id = this.thisTopicId
    this.getList(id)
    this.sessionNodeList = JSON.parse(window.sessionStorage.getItem("sessionNodeList")) || []

    document.addEventListener('mousemove', this.moveDiv)
    document.addEventListener('mouseup', this.moveDisAble)
    console.log('this.sessionNodeList', this.question);
  },
  beforeRouteLeave (to, from, next) {
    if (to.path != '/doPaper') {
      window.sessionStorage.removeItem('tipic_id')
      window.sessionStorage.removeItem('sessionNodeList')
    }
    next()

  },
  methods: {
    fatie () {
      let form = {
        board_id: '',
        discuss_title: '',
        discuss_content: '',
        question_id: '',
      }
      this.$refs.FormRef.dialogVisible = true
      this.$refs.FormRef.form = form
      // 这里不知道为什么 dialog的z-index比loading高
      setTimeout(() => {
        document.getElementsByClassName('v-modal')[0].style['z-index'] = 1998
      }, 100);
    },
    collect () {
      let form = {
        question_id: Number(this.question.question_id),
        tags: ''
      }
      this.$refs.TagRef.dialogVisible = true
      this.$refs.TagRef.form = form
      // this.getList(this.thisTopicId)
    },
    async cancelCollect () {
      console.log('thsi.question.question_favorite_id', this.question);

      this.$confirm("确定取消收藏这道题目？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await delCollect({
          id: this.question.question_favorite_id,
        })
        this.$message.success('取消收藏成功')
        this.has_favorite = 0

        if (this.$route.query.typeIndex == 2) {
          this.$router.go(-1)
      
        } else {
          this.getList(this.thisTopicId)
        }

        this.$EventBus.emit('resetCollect')


      }).catch(e => e)
    },
    reset () {
      this.$refs.mapRef.cancelSelected()
      this.showFlag = false
    },
    // 鼠标拖到调整作用大小宽度
    moveAble (e) {
      this.isMove = true
      this.clickX = e.clientX
      this.clickLeftWidth = this.$refs.errorLeftRef.clientWidth
      document.onselectstart = function () {
        return false
      }
    },
    moveDisAble (bool) {
      this.isMove = false
      document.onselectstart = function () {
        return true
      }
    },
    moveDiv (e) {
      if (this.isMove) {
        let divWidth = this.$refs.errorLeftRef.clientWidth
        let screenWidth = document.body.clientWidth
        let moveX = e.clientX - this.clickX
        if ((screenWidth - divWidth <= 375 && screenWidth - e.clientX <= 375) || (divWidth <= 514 && e.clientX <= 514)) {
          return
        }
        // this.$refs.errorLeftRef.style.width = e.clientX - 0.0521 * screenWidth + 'px'
        this.$refs.errorLeftRef.style.width = this.clickLeftWidth + moveX + 3 + 'px'
      }
    },

    nextTopic (num) {
      // num 1上一题，2下一题 
      if (num == 2) {
        this.thisTopicId = this.question.new_question
        if (this.thisTopicId == null) {
          this.$message.warning("已是最后一题")
          return
        }
        this.floatData = {}
        this.$refs.mapRef.cancelSelected()
      } else if (num == 1) {
        this.thisTopicId = this.question.last_question_id
        if (this.thisTopicId == null) {
          this.$message.warning("已是第一题")
          return
        }
        this.floatData = {}
        this.$refs.mapRef.cancelSelected()
      }
      window.sessionStorage.setItem("tipic_id", this.thisTopicId)
      this.getList(this.thisTopicId)
    },
    //处理讲义数据
    flatlectureList (arr) {
      let list = []
      arr.forEach(item => {
        if (!item.lecture_id) {
          return
        }
        let obj = {
          id: item.lecture_id,
          type: 'lecture',
          topic: item.lecture_name,
          parentId: 0,
          children: []
        }
        list.push(obj)
      });
      return list;
    },

    //处理考点数据
    flatCheckPointList (arr) {
      let list = []
      arr.forEach(item => {

        let obj = {
          type: 'checkpoint',
          children: []
        }
        if (item.check_point_2_id) {
          if (!item.check_point_2_id) {
            return
          }
          obj.id = item.check_point_2_id
          obj.parentId = item.check_point_id
          obj.topic = item.check_point_2_desc + '<span style="font-family: iconfont;font-size:26rem;vertical-align: -14%;line-height: 22rem;margin-right: -6px ">&#xe604;</span>'
        } else {
          if (!item.check_point_id) {
            return
          }
          obj.id = item.check_point_id
          obj.parentId = 0
          obj.topic = item.check_point_desc + '<span style="font-family: iconfont;font-size:26rem;vertical-align: -14%;line-height: 22rem;margin-right: -6px ">&#xe604;</span>'
        }

        if (item.chilren && item.chilren.length) {
          obj.children = this.flatCheckPointList(item.chilren)
        }

        list.push(obj);
      });
      return list;
    },

    //处理知识点数据
    flatKnowledgeList (arr) {
      let list = []
      arr.forEach(item => {
        let obj = {
          id: item.knowledge_id,
          type: 'knowledge',
          topic: item.knowledge_desc + '<span style="font-family: iconfont;font-size:26rem;vertical-align: -14%;line-height: 22rem;margin-right: -6px ">&#xe604;</span>',
          parentId: item.knowledge_parent_id,
          children: []
        }
        if (item.child && item.child.length) {
          obj.children = this.flatKnowledgeList(item.child)
        }
        list.push(obj)
      });
      return list;
    },
    //处理关键能力数据
    flatCapabilityType1List (arr) {
      let list = []
      arr.forEach(item => {
        let obj = {
          id: item.capability_id,
          type: 'capability_type1',
          topic: item.capability_name + '<span style="font-family: iconfont;font-size:26rem;vertical-align: -14%;line-height: 22rem;margin-right: -6px ">&#xe604;</span>',
          parentId: item.capability_parent_id,
          children: []
        }
        if (item.child && item.child.length) {
          obj.children = this.flatCapabilityType1List(item.child)
        }
        list.push(obj)
      });
      return list;
    },
    //处理学科素养
    flatCapabilityType2List (arr) {
      let list = []
      arr.forEach(item => {
        let obj = {
          id: item.capability_id,
          type: 'capability_type2',
          topic: item.capability_name + '<span style="font-family: iconfont;font-size:26rem;vertical-align: -14%;line-height: 22rem;margin-right: -6px ">&#xe604;</span>',
          parentId: item.capability_parent_id,
          children: []
        }
        if (item.child && item.child.length) {
          obj.children = this.flatCapabilityType2List(item.child)
        }
        list.push(obj)
      });
      return list;
    },



    async getList (id) {
      // if(this.thisTopicId==null)
      if (this.$route.query.question_id) {
        let params = {
          // question_id: this.thisTopicId || this.$route.query.question_id
          question_id: id

        }
        const { data } = await favoriteInfo(params)
        data.images = Object.values(data.images).flat()
        this.question = data
        this.comment = this.question.favorite_tags
      } else if (this.$route.query.user_paper_detail_id) {
        let params = {
          // user_paper_detail_id: this.thisTopicId || this.$route.query.user_paper_detail_id
          user_paper_detail_id: id

        }
        const { data } = await errorInfo(params)
        data.images = Object.values(data.images).flat()
        this.question = data
        // this.question.question_favorite_id = this.$route.query.question_favorite_id
        this.comment = this.question.comment_to_user
      }
      let list = {
        id: 'topic_root',
        topic: '题目',
        children: [
          // {
          //   id: 'knowledge_root',
          //   topic: '知识点',
          //   children: this.flatKnowledgeList(this.question.question_knowledge)
          // },
          // {
          //   id: 'checkpoint_root',
          //   topic: '考点',
          //   children: this.flatCheckPointList(this.question.question_check_point)
          // },
          // {
          //   id: 'lecture_root',
          //   topic: '讲义',
          //   children: this.flatlectureList(this.question.lecture)
          // }
        ]
      }
      if (this.question.question_knowledge && this.question.question_knowledge.length > 0) {
        list.children.push({
          id: 'knowledge_root',
          topic: '考点',
          children: this.flatKnowledgeList(this.question.question_knowledge)
        })
      }
      // if (this.question.question_check_point && this.question.question_check_point.length > 0) {
      //   list.children.push({
      //     id: 'checkpoint_root',
      //     topic: '考点',
      //     children: this.flatCheckPointList(this.question.question_check_point)
      //   })
      // }
      if (this.question.lecture && this.question.lecture.length > 0) {
        list.children.push({
          id: 'lecture_root',
          topic: '讲义',
          children: this.flatlectureList(this.question.lecture)
        })
      }
      if (this.question.capability_type1 && this.question.capability_type1.length > 0) {
        list.children.push({
          id: 'capability_type1',
          topic: '关键能力',
          children: this.flatCapabilityType1List(this.question.capability_type1)
        })
      }
      if (this.question.capability_type2 && this.question.capability_type2.length > 0) {
        list.children.push({
          id: 'capability_type2',
          topic: '学科素养',
          children: this.flatCapabilityType2List(this.question.capability_type2)
        })
      }
      this.$refs.mapRef.init(list)
    },
    // 设置浮窗数据
    async setFloatData (id, parentId, type) {
      this.showFlag = true
      let node = this.isSessionNode(type, id) //查找节点有无缓存
      if (node.id) {
        this.floatData = node.data
        this.$nextTick(() => {
          this.$refs.windowRef.windowData = node.data
        })
        return
      }
      let form
      if (type == 'checkpoint') {
        const { data } = await getPoint({
          check_point_id: id
        })
        form = {
          type: 'checkPoint',
          // check_point_id: parentId ? parentId : id,
          check_point_2_id: parentId ? data.check_point_id : '',//二级考点
          check_point_id: parentId ? '' : data.check_point_id,//一级级考点
          title: data.check_point_desc,//标题
          star: data.check_point_star ? data.check_point_star : 0,//考点星级 （二级考点没有星级）
          time: data.paper_time,//做卷时间
          score: data.score,//得分率
          count: data.count,
          video_list: data.video_list//关联的考点视频
        }
        // console.log(data)
        this.floatData = form
        this.$nextTick(() => {
          this.$refs.windowRef.windowData = form
        })
        this.setSessionNode('checkpoint', id, form)
      }
      else if (type == 'knowledge') {
        const { data } = await getKnowledge({
          knowledge_id: id
        })
        form = {
          type: 'knowledge',
          knowledge_id: data.knowledge_id,
          subjectId: data.subject_id,
          title: data.knowledge_desc,//标题
          star: data.knowledge_point_star ? data.knowledge_point_star : 0,//知识点星级
          time: data.paper_time,//做卷时间
          score: data.score,//得分率
          count: data.count,
          video_list: data.video_list//关联的考点视频
        }
        // console.log(data)
        this.floatData = form
        this.$nextTick(() => {
          this.$refs.windowRef.windowData = form
        })
        this.setSessionNode('knowledge', id, form)
      } else if (type == 'lecture') {
        const { data } = await getLecture({
          lecture_id: id
        })
        form = {
          type: 'lecture',
          title: data.lecture_name,//标题
          time: data.paper_time,//做卷时间
          score: data.score,//得分率
          count: data.count,
          lecture_file: data.lecture_file//关联的考点视频
        }
        // console.log(data)
        this.floatData = form
        this.$nextTick(() => {
          this.$refs.windowRef.windowData = form
        })
        this.setSessionNode('lecture', id, form)

      } else if (type == 'capability_type1') {
        const { data } = await getCapability({
          capability_id: id
        })
        form = {
          type: 'capability_type1',
          capability_id: data.capability_id,
          subjectId: data.subject_id,
          title: data.capability_name,//标题

          time: data.paper_time,//做卷时间
          score: data.score,//得分率
          count: data.count,
          video_list: data.video_list//关联的考点视频
        }
        // console.log(data)
        this.floatData = form
        this.$nextTick(() => {
          this.$refs.windowRef.windowData = form
        })
        this.setSessionNode('capability', id, form)

      }
      else if (type == 'capability_type2') {
        const { data } = await getCapability({
          capability_id: id
        })
        form = {
          type: 'capability_type2',
          capability_id: data.capability_id,
          subjectId: data.subject_id,
          title: data.capability_name,//标题
          time: data.paper_time,//做卷时间
          score: data.score,//得分率
          count: data.count,
          video_list: data.video_list//关联的考点视频
        }
        // console.log(data)
        this.floatData = form
        this.$nextTick(() => {
          this.$refs.windowRef.windowData = form
        })
        this.setSessionNode('capability', id, form)

      }

    },
    // 修改评论
    async editComment () {
      if (this.comment && this.comment != this.question.favorite_tags) {
        if (this.$route.query.user_paper_detail_id) {
          const { data } = await editComment({
            user_paper_detail_id: this.thisTopicId,
            comment_to_user: this.comment
          })
          this.question.favorite_tags = this.comment
        } else if (this.$route.query.question_id) {
          const { data } = await editCollectComment({
            id: this.question.question_favorite_id,
            tags: this.comment
          })
          this.question.favorite_tags = this.comment
        }

      }
      this.isFocus = false
    },
    // 评论显示文字/输入框
    showInput () {
      this.isFocus = true
    },
    isSessionNode (type, id) {
      this.sessionNodeList = JSON.parse(window.sessionStorage.getItem("sessionNodeList")) || []
      if (!this.sessionNodeList) return false
      let node = this.sessionNodeList.find(item => item.type == type && item.id == id
      )

      if (!node) { return false }
      return node
    },
    setSessionNode (type, id, data) {
      this.sessionNodeList.push({
        type: type,
        id,
        data
      })
      window.sessionStorage.setItem("sessionNodeList", JSON.stringify(this.sessionNodeList))
    }

  }
}
</script>

<style lang="scss" scoped>
.analysis_warp {
  width: 100%;
  overflow: hidden;
  // height: 100vh;
  background: #f6f6f6;
  font-family: PingFang SC-Regular, PingFang SC;
  .top {
    width: 100%;
    height: 100rem;
  }
  .topic {
  font-size: 20rem;
}

  .contents {
    display: flex;
    // height: calc(100vh - 100rem);
    // height: 100vh;
    margin-bottom: 10rem;
    .left {
      // width: 680rem;
      width: 50%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      .content {
    // width: calc(100% - 60rem);
    // overflow: auto;
    background: #ffffff;
    margin:0 30rem;
    .coll_wrap {
      margin-top: 30rem;
    }
  }
      .box {
        overflow: hidden;
        border-radius: 5rem;
        margin: 28rem 10rem 19rem 28rem;
        margin-right: 10px;
        background: #fff;
        min-height: 393rem;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        .title {
          width: 100%;
          height: 54rem;
          line-height: 54rem;
          background: #eff8ff;
          padding: 0 19rem;
          box-sizing: border-box;
          font-size: 20rem;
          justify-content: space-between;
          // font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999;
          display: flex;
          text-align: left;
          .txtTitle{
        
          }
          .next_btn {
            display: flex;
            gap: 2rem;
            .txt {
              display: flex;
              flex-direction: column;
              font-size: 16rem;
              color: #2196f3;
              align-items: center;
              width:50rem
            }
          }
          .txt {
            img {
              // margin-top: 14rem;
              // height: 10rem;
              width: 32rem;
              height: 32rem;
            }
          }
        }
        .topic_ontent {
          padding: 9rem 19rem;
          font-size: 22rem;
          min-height: 280rem;
          color: #333;
          line-height: 40rem;
          overflow: auto;
          ::v-deep img {
            vertical-align: middle;
          }
          .topic {
            ::v-deep p {
              width: 100%;
              overflow: auto;
            }
          }
        }

        .answer,
        .answerImg,
        .comment {
          padding: 19rem;
          font-size: 24rem;
          color: #333;
          line-height: 40rem;
          .comment_title {
            font-weight: 600;
          }
          div {
            margin-bottom: 10rem;
          }
        }
        .answerImg {
          img {
            width: 100%;
          }
        }
      }
      .other {
        padding: 9rem 19rem;
        .subtitle {
          font-size: 24rem;
          // font-weight: 700;
          color: #eff8ff;
          line-height: 50rem;
        }
        .list {
          font-size: 22rem;
          line-height: 40rem;
          font-weight: 400;
          color: rgba(102, 102, 102, 0.85);
        }
      }
    }
    .right {
      flex: 1;
      // width: 1080rem;
      margin: 29rem 24rem 29rem 16rem;
      height: calc(100vh - 46rem);
      background: #fff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 0px 0px;
      .map {
        height: 100%;
      }
    }

    .float_window {
    }
    .comment_text {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .comment_icon {
      width: 28rem;
      height: 28rem;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
  ::v-deep .el-collapse-item__header {
  background: #f6f6f6;
  border-radius: 10rem;
  font-size: 20rem;
  font-weight: 500;
  color: #333333;
  text-indent: 20rem;
  margin-top: 20rem;
}
::v-deep .el-collapse-item__wrap {
  border-bottom: none;
}
::v-deep .el-collapse {
  border: none;
}
::v-deep .el-collapse-item__content {
  margin-left: 20rem;
  margin-top: 20rem;
}
::v-deep .el-collapse-item__arrow.is-active {
  margin-top: -20rem;
}
  .move_line {
    width: 4px;
    height: 100vh;
    background: #fff;
    box-shadow: 0px 0px 4px #bbc;
    border: 0;
    padding: 0;
    cursor: col-resize;
  }
  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  /* 滚动条上的按钮 (上下箭头). */
  ::-webkit-scrollbar-button {
    display: none;
  }
  /* 滚动条上的滚动滑块. */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(202, 202, 210, 0.8);
    border-radius: 50px;
    cursor: pointer;
  }
  /* 滚动条没有滑块的轨道部分 */
  ::-webkit-scrollbar-track-piece {
    border-radius: 20px;
  }
  /* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  ::v-deep .el-input--medium {
    font-size: 24rem;
  }
}
</style>